import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import { serviceContainer, KeycloakService } from 'services';
import {
  IOffice,
  RegionDto,
  Reservation,
  CreateReservation,
  DatesString,
  IRoom,
  IFavorites,
  CreateFavorites,
  IWorkspace, IRegion, PlaceType,
  SearchAvailableDto,
} from 'types/commonTypes';
import {
  CreateReservationBody,
  CreateReservationResponse,
  IDepartment,
  IDivision,
  IDomain,
  IRole,
  IUsersPaginationParams,
  IWorkspacesPaginationParams,
  NotificationDto,
  NotificationActivityDto,
  CreateVersionDto,
  UpdateUserData,
  User,
  Scheduler,
  VersionDto,
  INewsletterDto,
  ICreateNewsletterDto,
  PaginationParamsNewsletter,
  ISearchAvailablePaginationParams,
} from 'types';

import {
  API_URL,
  favoritesURL,
  enableSchedulersURL,
  disableSchedulersURL,
  getSchedulerURL,
  getAllSchedulersURL,
  getAllUsersURL,
  getDivisionsURL,
  getRegionsURL,
  getReservationsURL,
  getRolesURL,
  getRoomsURL,
  getWorkspacesURL,
  restorePasswordPublicURL,
  restorePasswordURL,
  updateUserEmailURL,
  usersURL,
  updatePasswordURL,
  changeReservationURL,
  officesURL,
  sendMessageToSupportURL,
  departmentsURL,
  domainsURL,
  currentUserURL,
  currentUserImageURL,
  roomBackgroundURL,
  roomPreviewURL,
  updateUserDataURL,
  notificationsURL,
  notificationVersionsURL,
  workspacesAllURL,
  newslettersURL,
  POSTroomBackgroundURL,
  POSTroomPreviewURL,
  searchAvailableURL,
} from './urls';
import { IOfficeData, IOfficeExternal, OfficeDto } from 'pages/AdministrationPage/OfficesTable/OfficesTable.type';
import { IPremiseData, IPremiseExternal } from 'pages/AdministrationPage/PremisesTable/PremisesTable.type';
import {
  IWorkspaceData,
  RoomDto,
  TWorkspaceTableExternal,
  WorkspaceDto,
} from 'pages/AdministrationPage/WorkspacesTable/WorkspacesTable.type';
import Cookies from 'js-cookie';

export class ApiService {
  private _keycloakService: KeycloakService;
  private _axiosInstance: AxiosInstance;
  private _tokenName = 'access_token';
  private _API_URL = API_URL;
  private urlsToIgnoreBeforeRedirect = [
    roomPreviewURL,
  ];

  constructor(keycloakService: KeycloakService) {
    this._keycloakService = keycloakService;
    this._axiosInstance = axios.create();
    this._axiosInstance.interceptors.request.use((config) => {
      const token = Cookies.get(this._tokenName);
      if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
      }
      return config;
    });
    this._axiosInstance.interceptors.response.use(this.handleSuccess, this.handleError);
  }

  handleSuccess(response: AxiosResponse) {
    return response;
  }

  handleError(error: AxiosError) {
    const is502 = error.message.match('502')?.[0];
    if (is502) {
      if (this.urlsToIgnoreBeforeRedirect.some((value) => error.request.responseURL.startsWith(value))) return;
      window.location.pathname = '/notavailable';
    }
    return Promise.reject(error);
  }

  createAxiosConfig(keycloakToken: string): AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloakToken}`,
      },
    };
  }

  updateInstance(keycloakToken: string) {
    serviceContainer.loginStore.token = keycloakToken;
    Cookies.set(serviceContainer.apiService.tokenName, keycloakToken);
    this._axiosInstance = axios.create(this.createAxiosConfig(keycloakToken));
  }

  get API_URL() {
    return this._API_URL;
  }

  get tokenName() {
    return this._tokenName;
  }

  /// Планировщик
  getAllSchedulers = () =>
    this._axiosInstance.get< Scheduler[] >( getAllSchedulersURL );

  getScheduler = () =>
    this._axiosInstance.get<{ data: Scheduler }>(getSchedulerURL );

  enableScheduler = (scheduler_id: string) =>
    this._axiosInstance.post<{ data: Scheduler }>(enableSchedulersURL, {}, { params: { scheduler_id } });

  disableScheduler = (scheduler_id: string) =>
    this._axiosInstance.post<{ data: Scheduler }>(disableSchedulersURL, {}, { params: { scheduler_id } });

  getAllUsers = (paginationParams?: IUsersPaginationParams) => {
    const params = paginationParams || {};
    return this._axiosInstance.get<{
      meta?: { totalCount: number };
      data: User[];
    }>(getAllUsersURL, { params });
  };

  getCurrentUser = () =>
    this._axiosInstance.get<{ data: User }>(currentUserURL);

  updateUser = (user: User) => this._axiosInstance.put(usersURL, {
    userId: user.userId,
    userName: user.userName,
    email: user.email ? user.email : '',
    userRole: user.userRole,
    division: user.division ? user.division : '',
    disabled: user.disabled,
  });

  updateUserEmail = (user: User) => this._axiosInstance.put(updateUserEmailURL, {
    email: user.email ? user.email : '',
  });

  getUserImageById = (filename: string) =>
    this._axiosInstance.get<Blob>(`${currentUserImageURL}/${filename}`, {
      responseType: 'blob',
    });
  // регионы
  getRegions = () => this._axiosInstance.get<{ data: RegionDto[] }>(getRegionsURL);

  createRegion = (params: IRegion) => this._axiosInstance.post<{ data: { data: RegionDto } }>(getRegionsURL, params);

  deleteRegion = (regionId: string) => this._axiosInstance.delete<{ data: { data: RegionDto } }>(getRegionsURL, { params: { regionId } });

  updateRegion = (params: IRegion ) =>
    this._axiosInstance.put<{ data: { data: RegionDto } }>(getRegionsURL, params, { params: { regionId: params.regionId } });

  getFavoritesByUserId = (userId: string) =>
    this._axiosInstance.get<{ data: IFavorites[] }>(favoritesURL, { params: { userId } });

  createFavorites = (body: CreateFavorites) =>
    this._axiosInstance.post<{ data: IFavorites[] }>(favoritesURL, body);

  deleteFavorites = (favoriteId : string) =>
    this._axiosInstance.delete<{ data: IFavorites[] }>(favoritesURL, { params: { favoriteId } });

  getReservations = (workspace_id?: string, isCalendar?: boolean) =>
    this._axiosInstance.get<{ data: Reservation<DatesString>[], permission: boolean }>(getReservationsURL, {
      params: { workspace_id, isCalendar, size: 10000 },
    });

  getReservationsByWtype = (wtype: string, search: string) =>
    this._axiosInstance.get<{ data: Reservation<DatesString>[] }>(getReservationsURL, {
      params: { wtype, search, isCalendar: false, size: 10000 },
    });

  createReservation = (data: CreateReservation) =>
    this._axiosInstance.post<CreateReservationBody, { data: CreateReservationResponse }>(
      changeReservationURL,
      data,
    );

  deleteReservation = (reservation_id: string) =>
    this._axiosInstance.delete(changeReservationURL, { params: { reservation_id } });

  updateReservation = (data: CreateReservation, reservation_id: string) =>
    this._axiosInstance.put<CreateReservationBody, { data: CreateReservationResponse }>(
      changeReservationURL,
      data,
      { params: { reservation_id } },
    );

  getRoles = () => this._axiosInstance.get<IRole[]>(getRolesURL);

  restorePassword = (email: string) =>
    this._axiosInstance.post(restorePasswordURL, { email });

  updateUserData = (userId: string) =>
    this._axiosInstance.patch(`${updateUserDataURL}/${userId}`);

  restorePasswordPublic = (email: string) =>
    this._axiosInstance.post(restorePasswordPublicURL, { email });

  addUser = (user: User)=>
    this._axiosInstance.post(usersURL, {
      userId:user.userId,
      email:user.email,
      username:user.userName,
      divisionId:user.division,
    });

  updatePassword = (body: { password: string }) =>
    this._axiosInstance.put<{ data: UpdateUserData }>(updatePasswordURL, body);

  sendMessageToSupport = (data: object) =>
    this._axiosInstance.post(sendMessageToSupportURL, data);
  // блоки
  getDepartments = () => this._axiosInstance.get<{ data: IDepartment[] }>(departmentsURL);

  createDepartment = (body: IDepartment) => this._axiosInstance.post<{ data: IDepartment }>(departmentsURL, body);

  updateDepartment = (departmentId: string, body: IDepartment) =>
    this._axiosInstance.put<{ data: IDepartment }>(departmentsURL, body, { params: { departmentId } });

  deleteDepartment = (departmentId: string) =>
    this._axiosInstance.delete<{ data: IDepartment }>(departmentsURL, { params: { departmentId } });

  // практики
  getDivisions = () =>
    this._axiosInstance.get<{ data: IDivision[] }>(getDivisionsURL);

  createDivision = (body: IDivision) => this._axiosInstance.post<{ data: IDivision }>(getDivisionsURL, body);

  updateDivision = (divisionId: string, body: IDivision) =>
    this._axiosInstance.put<{ data: IDivision }>(getDivisionsURL, body, { params: { divisionId } });

  deleteDivision = (divisionId: string) =>
    this._axiosInstance.delete<{ data: IDivision }>(getDivisionsURL, { params: { divisionId } });

  // домены
  getDomains = () =>
    this._axiosInstance.get<{ data: IDomain[] }>(domainsURL);

  createDomain = (body: IDomain) => this._axiosInstance.post<{ data: IDivision }>(domainsURL, body);

  updateDomain = (domainName: string, body: IDomain) =>
    this._axiosInstance.put<{ data: IDomain }>(domainsURL, body, { params: { domainName } });

  deleteDomain = (domainName: string) =>
    this._axiosInstance.delete<{ data: IDomain }>(domainsURL, { params: { domainName } });

  // офисы
  getOfficeByRegionId = (region_id: string) =>
    this._axiosInstance.get<{ data: IOffice[] }>(officesURL, { params: { region_id } });

  getAllOffices = () => this._axiosInstance.get<{ data: IOffice[] }>(`${officesURL}/all`);

  createOffice = (body: IOfficeExternal) => this._axiosInstance.post<{ data: IOfficeData }>(officesURL, body);

  updateOffice = (officeId: string, body: IOfficeExternal) =>
    this._axiosInstance.put<{ data: IOfficeData }>(officesURL, body, { params: { officeId } });

  deleteOffice = (officeId: string) =>
    this._axiosInstance.delete<{ data: IOfficeData }>(officesURL, { params: { officeId } });

  // помещения
  getRooms = (office_id: string) =>
    this._axiosInstance.get<{ data: IRoom[] }>(getRoomsURL, { params: { office_id } });

  getAllRooms = () => this._axiosInstance.get<{ data: IRoom[] }>(`${getRoomsURL}/all`);

  createRoom = (body: IPremiseExternal) => this._axiosInstance.post<{ data: IPremiseData }>(getRoomsURL, body);

  updateRoom = (roomId: string, body: IPremiseExternal) =>
    this._axiosInstance.put<{ data: IPremiseData }>(getRoomsURL, body, { params: { roomId } });

  deleteRoom = (roomId: string) =>
    this._axiosInstance.delete<{ data: IPremiseData }>(getRoomsURL, { params: { roomId } });

  //  рабочие места
  getWorkspaces = (room: string):Promise<{ data: { data: PlaceType[] } }> =>
    this._axiosInstance.get(getWorkspacesURL, { params: { room } });

  getAllWorkspaces = (paginationParams?: IWorkspacesPaginationParams) => {
    const params = paginationParams || {};
    return this._axiosInstance.get<{
      meta?: { totalCounts: number };
      data: IWorkspace[];
    }>(`${getWorkspacesURL}/all`, { params });
  };

  createWorkspace = (body: TWorkspaceTableExternal) => this._axiosInstance.post<{ data: IWorkspaceData }>(getWorkspacesURL, body);

  updateWorkspace = (workspaceId: string, body: TWorkspaceTableExternal) =>
    this._axiosInstance.put<{ data: IWorkspaceData }>(getWorkspacesURL, body, { params: { workspaceId } });

  deleteWorkspace = (workspaceId: string) =>
    this._axiosInstance.delete<{ data: IPremiseData }>(getWorkspacesURL, { params: { workspaceId } });

  getPreviewImage = (imgName: string): Promise<{ data: string | Blob }> => {
    const extension = imgName.split('.').pop();
    return this._axiosInstance.get(`${roomPreviewURL}/${imgName}`, {
      headers: {
        'Content-Type': `image/${extension}`,
      },
      responseType: 'blob',
    });
  };

  getRoomBackground = (imgName: string): Promise<{ data: string | Blob }> => {
    return this._axiosInstance.get(`${roomBackgroundURL}/${imgName}`, {
      headers: {
        'Content-Type': 'image/svg',
      },
      responseType: 'text',
    });
  };


  uploadImage = (file: File, roomId: string, preview?:boolean, office?: boolean) => {
    const data = new FormData();
    data.append(preview ? 'preview' : 'image', file, file.name);
    const onError = () => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: `Что-то пошло не так. ${preview ? 'Превью' : 'Фон'} ${office ? 'офиса' : 'комнаты'} не удалось загрузить`,
        severity: 'error',
      });
    };
    this._axiosInstance.post(
      `${preview ?
        POSTroomPreviewURL :
        POSTroomBackgroundURL}/${roomId}`,
      data,
      {
        params: { roomId }, headers: {
          'Content-Type': 'multipart/form-data',
        } },
    ).then(() => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: `${preview ? 'Превью' : 'Фон'} ${office ? 'офиса' : 'комнаты'} успешно загружен`,
      });
    }, onError).catch(onError);
  };

  uploadWorkspaces = (workspaces: WorkspaceDto[]) => {
    this._axiosInstance.post<{ data: WorkspaceDto[] }>(`${API_URL}/secured/workspaces/all`, workspaces);
  };

  updateWorkspaceViaEditor = (workspaceId: string, body: WorkspaceDto) =>
    this._axiosInstance.put<{ data: WorkspaceDto }>(getWorkspacesURL, body, { params: { workspaceId } });

  updateWorkspacesAllViaEditor = (body: WorkspaceDto[]) => this._axiosInstance.put<{ data: WorkspaceDto }[]>(workspacesAllURL, body);

  createWorkspaceViaEditor = (body: WorkspaceDto) => this._axiosInstance.post<{ data: WorkspaceDto }>(getWorkspacesURL, body);

  createWorkspacesAllViaEditor = (body: WorkspaceDto[]) => this._axiosInstance.post(workspacesAllURL, body);

  createRoomViaEditor = (body: RoomDto) => this._axiosInstance.post<{ data: IPremiseData }>(getRoomsURL, body);

  updateRoomViaEditor = (roomId: string, body: RoomDto) =>
    this._axiosInstance.put(getRoomsURL, body, { params: { roomId } });

  createOfficeViaEditor = (body: OfficeDto) => this._axiosInstance.post(officesURL, body);

  updateOfficeViaEditor = (officeId: string, body: OfficeDto) =>
    this._axiosInstance.put(officesURL, body, { params: { officeId } });

  // уведомления
  getAllNotifications = () =>
    this._axiosInstance.get<NotificationDto[]>(notificationsURL);

  getNotificationById = (id: string | number) =>
    this._axiosInstance.get<NotificationDto>(`${notificationsURL}/${id}`);

  getVersionNotificationById = (notification_id: string | number) =>
    this._axiosInstance.get<VersionDto[]>(`${notificationVersionsURL}`, {
      params: { notification_id },
    });

  createNotificationVersion = (notificationId: string | number, body: CreateVersionDto) =>
    this._axiosInstance.post<VersionDto>(`${notificationVersionsURL}/${notificationId}`, body);

  updateVersionActivity = (notificationId: string | number, activeVersion: string) =>
    this._axiosInstance.put<VersionDto>(`${notificationsURL}/${notificationId}`, {}, {
      params: { activeVersion },
    });

  deleteVersion = (versionId: string) =>
    this._axiosInstance.delete<NotificationDto>(`${notificationVersionsURL}/${versionId}`);

  updateVersion = (versionId: string | number, body: CreateVersionDto) =>
    this._axiosInstance.put<VersionDto>(`${notificationVersionsURL}/${versionId}`, body);

  updateNotificationActivity = (notificationId: string | number, body: NotificationActivityDto) =>
    this._axiosInstance.patch<NotificationDto>(`${notificationsURL}/${notificationId}`, body);

  // рассылки
  getAllNewsletters = (sortingColumn: string, sortingMethod: string, paginationParams?: PaginationParamsNewsletter) => {
    return this._axiosInstance.get<{
      meta: {
        totalCount: number,
        totalPages: number,
      };
      data: INewsletterDto[];
    }>(newslettersURL, { params: {
      sortingColumn,
      sortingMethod,
      page: paginationParams?.page,
      pageSize: paginationParams?.pageSize,
      search: paginationParams?.search,
      status: paginationParams?.status,
      dateFrom: paginationParams?.dateFrom,
      dateTo: paginationParams?.dateTo,
    } });
  };

  createNewsletter = (body: ICreateNewsletterDto) =>
    this._axiosInstance.post<INewsletterDto>(newslettersURL, body);

  sendMeNewsletter = (id: string | number) =>
    this._axiosInstance.post(`${newslettersURL}/sendme/${id}`);

  updateNewsletter = (id: number, body: ICreateNewsletterDto) =>
    this._axiosInstance.put<INewsletterDto>(`${newslettersURL}/${id}`, body);

  deleteNewsletter = (id: number) =>
    this._axiosInstance.delete(`${newslettersURL}/${id}`);

  sendFileNewsletter = (id: number, data: object) =>
    this._axiosInstance.post(`${newslettersURL}/attachment/add/${id}`, data);

  deleteFileNewsletter = (id: number | undefined, filepath: string) => {
    return this._axiosInstance.delete<INewsletterDto>(`${newslettersURL}/attachment/delete/${id}`, { params: {
      filepath,
    } });
  };

  getFileNewsletter = (filepath: string) => {
    return this._axiosInstance.delete(`${newslettersURL}/attachment`, { params: {
      filepath,
    } });
  };

  getSearchAvailable = (paginationParams: ISearchAvailablePaginationParams) => {
    return this._axiosInstance.get<SearchAvailableDto[]>(searchAvailableURL, { params: {
      reservationType: paginationParams.reservationType,
      workspaceType: paginationParams.workspaceType,
      officeId: paginationParams?.officeId,
      roomId: paginationParams?.roomId,
      from: paginationParams?.from,
      to: paginationParams?.to,
    } });
  };
}
