import { serviceContainer } from '../ServiceContainer';
import { toExternalData, toInternalData } from './helpers';
import { TWorkspaceTableInternal } from '../../pages/AdministrationPage/WorkspacesTable/WorkspacesTable.type';
import { IWorkspacesPaginationParams, ISearchAvailablePaginationParams } from 'types/otherTypes';

export class WorkspacesService {
  
  async loadWorkspaces(roomId: string) {
    const onError = () => {
      serviceContainer.workspacesStore.setWorkspacesByRoomId(roomId, []);
    };
    serviceContainer.apiService.getWorkspaces(roomId).then((res) => {
      serviceContainer.workspacesStore.setWorkspacesByRoomId(roomId, res.data.data);
    }, onError).catch(onError);
  }

  async loadAllWorkspaces() {
    try {
      serviceContainer.workspacesStore.workspacesLoading = true;
      const r = await serviceContainer.apiService.getAllWorkspaces();
      serviceContainer.workspacesStore.workspacesTable = toInternalData(r.data.data);
    } finally {
      serviceContainer.workspacesStore.workspacesLoading = false;
    }
  }

  async loadWorkspacesByPagination(paginationParams?: IWorkspacesPaginationParams) {
    const data = (await serviceContainer.apiService.getAllWorkspaces(paginationParams)).data;
    serviceContainer.workspacesStore.workspacesTable = data.data.map((el)=>({
      workspaceId: el.id,
      roomName: el.room.name,
      workspaceNumber: String(el.workspace_number),
      workspaceType: el.type,
    }));
    serviceContainer.workspacesStore.setWorkspacesTypeOption(['locker', 'desktop', 'desktop_fo', 'meetingroom', 'desktop_na']);
    return data;
  }

  async addWorkspace(params: TWorkspaceTableInternal) {
    const item = toExternalData(params);
    await serviceContainer.apiService.createWorkspace(item).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно добавлено ${item.workspaceId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    serviceContainer.workspacesStore.setIsAddingWorkspace(false);
    await serviceContainer.workspacesService.loadWorkspacesByPagination(serviceContainer.workspacesStore.paginationParams);
  }

  async updateWorkspace(params: TWorkspaceTableInternal) {
    const item = toExternalData(params);
    await serviceContainer.apiService.updateWorkspace(params.workspaceId, item).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно изменено ${item.workspaceId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.workspacesService.loadWorkspacesByPagination(serviceContainer.workspacesStore.paginationParams);
  }

  async deleteWorkspace(workspaceId: string) {
    await serviceContainer.apiService.deleteWorkspace(workspaceId).then((res) => {
      if (res) {
        serviceContainer.snackbarStore.setSnackbar({
          open: true,
          message: `Успешно удалено ${serviceContainer.workspacesStore.workspaceInfoForEdit?.workspaceId}`,
          severity: 'success',
        });
      }
    }).catch((error) => {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
        severity: 'error',
      });
    });
    await serviceContainer.workspacesService.loadWorkspacesByPagination(serviceContainer.workspacesStore.paginationParams);
  }

  async loadWorkspacesAvailable(paginationParams: ISearchAvailablePaginationParams) {
    const { data } = await serviceContainer.apiService.getSearchAvailable(paginationParams);
    serviceContainer.workspacesStore.workspacesAvailable = data;
    return data;
  }
}
