import { serviceContainer } from './';
import { 
  CreateVersionDto, 
  VersionDto, 
  NotificationActivityDto, 
  NotificationDto, 
  PaginationParamsNewsletter, 
  ICreateNewsletterDto, 
  INewsletterDto,
} from 'types';

export class DistributionService {
  async loadAllNotifications() {
    try {
      serviceContainer.distributionStore.isLoading = true;
      const { data } = await serviceContainer.apiService.getAllNotifications();
      const newNotificationsArray = await Promise.all(
        data.map(async (item) => {
          const dataVersion = await serviceContainer.apiService.getVersionNotificationById(item.id);
          const resultVersionAndNotification = {
            ...item,
            versions: dataVersion.data,
          };
          return resultVersionAndNotification;
        }),
      );
      serviceContainer.distributionStore.allNotifications = newNotificationsArray;
    } catch (error: any) {
      serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
    } finally {
      serviceContainer.distributionStore.isLoading = false;
    }
  }

  async createNotificationVersion(notification: NotificationDto, newVersion: CreateVersionDto) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите создание новой версии оповещения',
      description: `Создать новую версию «${newVersion.name}» для события «${notification.name}»?`,
      resolveTitle: 'Создать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          const { data } = await serviceContainer.apiService.createNotificationVersion(notification.id, newVersion);
          serviceContainer.distributionStore.currentVersion = data;
          await this.loadAllNotifications();
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
        }
      },
    });
  }

  async updateVersionActivity(notification: NotificationDto, version: VersionDto) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите изменение активной версии события',
      description: `Сделать актуальной версию «${version.name}»  для события «${notification.name}»?`,
      resolveTitle: 'Сделать актуальной',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          const { data } = await serviceContainer.apiService.updateVersionActivity(notification.id, version.id);
          serviceContainer.distributionStore.currentVersion = data;
          await this.loadAllNotifications();
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
        }
      },
    });
  }

  async updateNotificationActivity(notification: NotificationDto, body: NotificationActivityDto) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите изменение активации события',
      description: `Активировать событие «${notification.name}»?`,
      resolveTitle: 'Активировать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.updateNotificationActivity(notification.id, body);
          await this.loadAllNotifications();
        } catch (error) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
        }
      },
    });
  }

  async updateVersion(notification: NotificationDto, currentVersion: VersionDto, version: CreateVersionDto)  {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите изменение версии события',
      description: `Редактировать версию «${currentVersion.name}» для события «${notification.name}»?`,
      resolveTitle: 'Редактировать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.updateVersion(currentVersion.id, version);
          await this.loadAllNotifications();
        } catch (error) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
        }
      },
    });
  }

  async deleteVersion(notification: NotificationDto, version: VersionDto) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите удаление версии события',
      description: `Удалить версию «${version.name}» события «${notification.name}»?`,
      resolveTitle:'Удалить',
      rejectTitle:'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.deleteVersion(version.id);
          await this.loadAllNotifications();
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
        }
      },
    });
  }

  async loadAllNewsletters(sortingColumn: string, sortingMethod: string, paginationParams?: PaginationParamsNewsletter) {
    try {
      serviceContainer.distributionStore.isLoadingNewsletter = true;
      const res = (await serviceContainer.apiService.getAllNewsletters(sortingColumn, sortingMethod, paginationParams)).data;
      serviceContainer.distributionStore.allNewsletters = res.data;
      return res;
    } catch (error: any) {
      serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
    } finally {
      serviceContainer.distributionStore.isLoadingNewsletter = false;
    }
  }

  async createNewsletter(
    newNewsletter: ICreateNewsletterDto, 
    sortingColumn: string, 
    sortingMethod: string, 
    paginationParams: PaginationParamsNewsletter,
  ) {
    return serviceContainer.confirmationDialogStore.open({
      title: `Подтвердите создание ${newNewsletter.status === 'DRAFT' ? 'черновика' : 'новой рассылки'}`,
      description: `Создать ${newNewsletter.status === 'DRAFT' ? 'черновик' : 'новую рассылку'} "${newNewsletter.subject}"?`,
      resolveTitle: 'Создать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          const { data } = await serviceContainer.apiService.createNewsletter(newNewsletter);
          serviceContainer.distributionStore.newNewsletter = data;
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Рассылка "${newNewsletter.subject}" успешно создана`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async duplicateNewsletter(
    newNewsletter: ICreateNewsletterDto, 
    sortingColumn: string, 
    sortingMethod: string, 
    paginationParams: PaginationParamsNewsletter,
  ) {
    return serviceContainer.confirmationDialogStore.open({
      title: 'Подтвердите дублирование рассылки',
      description: `Дублировать рассылку "${newNewsletter.subject}"?`,
      resolveTitle: 'Дублировать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          const { data } = await serviceContainer.apiService.createNewsletter(newNewsletter);
          serviceContainer.distributionStore.newNewsletter = data;
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Рассылка "${newNewsletter.subject}" успешно продублирована`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async updateNewsletter(
    id: number, 
    newsletter: ICreateNewsletterDto, 
    sortingColumn: string, 
    sortingMethod: string, 
    paginationParams: PaginationParamsNewsletter,
  ) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите редактирование рассылки',
      description: `Редактировать рассылку "${newsletter.subject}"?`,
      resolveTitle: 'Редактировать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.updateNewsletter(id, newsletter);
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Рассылка "${newsletter.subject}" успешно изменена`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async planNewsletter(
    id: number, 
    newsletter: ICreateNewsletterDto, 
    sortingColumn: string, 
    sortingMethod: string, 
    paginationParams: PaginationParamsNewsletter,
  ) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите планирование рассылки',
      description: `Запланировать рассылку "${newsletter.subject}"?`,
      resolveTitle: 'Запланировать',
      rejectTitle: 'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.updateNewsletter(id, newsletter);
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Рассылка "${newsletter.subject}" успешно запланирована`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async canceledNewsletter(id: number, 
    newsletter: ICreateNewsletterDto, sortingColumn: string, 
    sortingMethod: string, paginationParams: PaginationParamsNewsletter) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите отмену отправки рассылки',
      description: `Отменить отправку рассылки "${newsletter.subject}"?`,
      resolveTitle: 'Да',
      rejectTitle: 'Нет',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.updateNewsletter(id, newsletter);
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Отправка рассылки "${newsletter.subject}" успешно отменена`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async deleteNewsletter(newsletter: INewsletterDto, sortingColumn: string, sortingMethod: string, paginationParams: PaginationParamsNewsletter) {
    return serviceContainer.confirmationDialogStore.open({
      title:'Подтвердите удаление рассылки',
      description: `Удалить рассылку "${newsletter?.subject}"?`,
      resolveTitle:'Удалить',
      rejectTitle:'Отменить',
      onFulfilled: async () => {
        try {
          await serviceContainer.apiService.deleteNewsletter(newsletter.id);
          const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
          if (res) serviceContainer.distributionStore.allNewsletters = res.data;
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message: `Рассылка "${newsletter.subject}" успешно удалена`,
            severity: 'success',
          });
        } catch (error: any) {
          serviceContainer.snackbarStore.showHttpErrorSnackbar(error);
          serviceContainer.snackbarStore.setSnackbar({
            open: true,
            message:`${error?.response.data.statusCode}: ${error?.response.data.message}`,
            severity: 'error',
          });
        }
      },
    });
  }

  async sendMeNewsletter(newsletter: INewsletterDto, sortingColumn: string, sortingMethod: string, paginationParams: PaginationParamsNewsletter) {
    try {
      await serviceContainer.apiService.sendMeNewsletter(newsletter.id);
      const res = await this.loadAllNewsletters(sortingColumn, sortingMethod, paginationParams);
      if (res) serviceContainer.distributionStore.allNewsletters = res.data;
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message: `Рассылка "${newsletter.subject}" успешно отправлена`,
        severity: 'success',
      });
    } catch (error: any) {
      serviceContainer.snackbarStore.setSnackbar({
        open: true,
        message:`${error}`,
        severity: 'error',
      });
    }
  }
}
