import { IOffice } from '../../types/commonTypes';
import { IOfficeExternal, TOfficesTableInternal } from '../../pages/AdministrationPage/OfficesTable/OfficesTable.type';

export const toInternalData = (data: IOffice[]): TOfficesTableInternal[] => {
  return data.map(item => {
    const officeNumbers = item.attributes.id.split('.');
    return {
      officeId: item.attributes.id,
      regionId: item.attributes.region.id,
      officeNumber: officeNumbers[officeNumbers.length - 1],
      officeName: item.attributes.name,
      address: item.attributes.address,
      coordinates: item.attributes.geolocation.split(', '),
      active: item.attributes.active,
    };
  });
};

export const toInternalRegionsOption = (data: IOffice[]): { id: string; description: string; }[] => {
  function unique(arr: string[]) {
    return Array.from(new Set(arr));
  }

  const ids = unique(data.map(item => {
    return item.attributes.region.id;
  }));

  const newData = ids.map(id => {
    const description = data.find((it) => it.attributes.region.id === id)?.attributes.region.name ?? '';
    return {
      id,
      description: description,
    };
  });

  return newData;
};

export const toInternalOffice = (data: TOfficesTableInternal): IOfficeExternal => {
  return {
    officeId: data.officeId,
    regionId: data.regionId,
    officeNumber: data.officeNumber,
    officeName: data.officeName,
    address: data.address,
    // todo точно стринг?
    geolocation: data.coordinates.join(', '),
  };
};
