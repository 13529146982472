import { useState, SyntheticEvent, useEffect, MouseEvent, useRef } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Tab,
  Tabs,
  Menu,
  MenuItem,
} from '@mui/material';
import { format } from 'date-fns';
import {
  EventAvailable,
  DateRange,
  Sort,
} from '@mui/icons-material';
import { serviceContainer } from 'services';
import { DatesString, Reservation } from '../../../types/commonTypes';
import { IRowData } from '../../../types/otherTypes';
import BookingTable from '../BookingTable/BookingTable';
import AntSwitch from '../AntSwitch/AntSwitch';
import PageLoader from '../PageLoader/PageLoader';
import SearchForm from '../SearchForm/SearchForm';
import { isBookingActual, tabStyles, sortOptions } from './helpers';

export const Booking = observer(() => {
  const currentUserName = serviceContainer.usersStore.currentUser?.userName;
  const currentUserRole = serviceContainer.usersStore.currentUser?.userRole || '';
  
  const [tabsValue, setTabsValue] = useState(1);
  const [readableTabsValue, setReadableTabsValue] = useState('desktop');
  const [allReservations, setAllReservations] = useState<Reservation<DatesString>[] | undefined>();
  const [showAll, setShowAll] = useState(false);
  const [showActual, setShowActual] = useState(true);
  const [tableRows, setTableRows] = useState<IRowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof IRowData>('userName');
  const [searchQuery, setSearchQuery] = useState('');

  const previousSearchQuery = useRef('');
  const searchQueryChanged = previousSearchQuery.current !== searchQuery;
  const privilegedRoles = ['admin', 'superuser'];

  useEffect(() => serviceContainer.reservationsStore.clearWtype(), []);

  useEffect(() => {
    previousSearchQuery.current = searchQuery;
  }, [searchQuery]);

  useEffect(() => {
    setAllReservations(serviceContainer.reservationsStore.getReservationsByWtype(readableTabsValue));
    const updateReservations = async () => {
      setLoading(true);
      try {
        await serviceContainer.reservationsService.loadReservationsByWtype(readableTabsValue, searchQuery);
        setAllReservations(serviceContainer.reservationsStore.getReservationsByWtype(readableTabsValue));
        setLoading(false);
      } catch (e: any) {
        // Т.к. на бэке стоит ограничение по времени между запросами, пользователь может поймать 429 ошибку
        // часто переключая табы при первом входе, когда в reservationsStore пусто.
        // В случае 429 ошибки лоадер продолжает крутиться и через 1,5 секунды уходит повторный запрос.
        if (e.response.status === 429) {
          setTimeout(async () => {
            await serviceContainer.reservationsService.loadReservationsByWtype(readableTabsValue, searchQuery);
            setAllReservations(serviceContainer.reservationsStore.getReservationsByWtype(readableTabsValue));
            setLoading(false);
          }, 1500);
        }
      }
    };
    if (!serviceContainer.reservationsStore.getReservationsByWtype(readableTabsValue) && !searchQueryChanged) {
      updateReservations();
    }
    if (searchQueryChanged) {
      serviceContainer.reservationsStore.clearWtype();
      updateReservations();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readableTabsValue, searchQuery]);

  useEffect(() => {
    const rows: IRowData[] = [];
    serviceContainer.reservationsStore.getReservationsByWtype(readableTabsValue)?.forEach((reservation: Reservation<DatesString>) => {
      const {
        fname,
        dateStart,
        dateEnd,
        isPartTime,
        isFullDay,
        isPermanent,
        region,
        office,
        room,
        workspace,
        userName,
      } = reservation.attributes;

      if ((!workspace.wtype?.includes(readableTabsValue))
        || (!showAll && currentUserName !== userName)
        || (showActual && !isBookingActual(dateEnd, isPermanent))
        || (!showActual && isBookingActual(dateEnd, isPermanent))
      ) return;
    
      const dateStartFormatted = format(new Date(dateStart), 'dd.MM.yyyy');
      const timeStartFormatted = isPartTime ?  format(new Date(dateStart), 'HH:mm') : '';
      const dateEndFormatted = isPartTime || isFullDay ? format(new Date(dateEnd || 0), 'dd.MM.yyyy') : '';
      const timeEndFormatted = isPartTime ? format(new Date(dateStart), 'HH:mm') : '';
      const dateStartForCompare = moment(dateStart).format('x');
      const dateEndForCompare = isPermanent ? '3376674000000' : moment(dateEnd).format('x');
    
      rows.push({
        userName: fname.userName,
        dateStart: dateStartForCompare,
        dateEnd: dateEndForCompare,
        dateStartFormatted: `${dateStartFormatted} ${timeStartFormatted}`,
        dateEndFormatted: `${dateEndFormatted} ${timeEndFormatted}`,
        regionName: region.name,
        regionId: region.id,
        officeName: office.name,
        officeId: office.id,
        roomName: room.name,
        roomId: room.id,
        reservationId: reservation.id,
        workspaceId: workspace.id,
        workspaceName: workspace.name,
        desktop: workspace.name,
        locker: workspace.name,
      });
    });
    setTableRows(rows);
  }, [readableTabsValue, showActual, allReservations, showAll, currentUserName]);
  
  const tabsChangeHandler = (event: SyntheticEvent, newValue: number) => {
    const tabsValuesDictionary: { [key: number]: string } = {
      0: 'meetingroom',
      1: 'desktop',
      2: 'locker',
    };
    setTabsValue(newValue);
    setReadableTabsValue(tabsValuesDictionary[newValue]);
  };
  
  const clearSearchHandler = () => {
    setSearchQuery('');
    serviceContainer.reservationsStore.clearWtype();
  };
  
  const actualChangeHandler = () => {
    setShowActual(!showActual);
  };
  
  const myBookingsClickHandler = () => {
    setShowAll(false);
  };
  
  const allBookingsClickHandler = () => {
    setShowAll(true);
  };
  
  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IRowData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <div className='booking_wrapper'>
        <header className='booking_header'>
          <div className='controls'>
            <FormControlLabel
              className='switch_label_mobile'
              control={
                <AntSwitch
                  onChange={actualChangeHandler}
                  checked={!showActual}
                />
              }
              label='Архив'
            />
            <Button
              className='sort_button'
              onClick={(event: React.MouseEvent<HTMLButtonElement>)=>setAnchorEl(event.currentTarget)}
              startIcon={<Sort/>}
            >
              {sortOptions[orderBy as keyof typeof sortOptions]}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={()=>setAnchorEl(null)}
              MenuListProps={{ className:'booking-sort-menu' }}
            >
              {Object.entries(sortOptions).filter(([key, value])=>key !== 'desktop' || readableTabsValue === 'desktop').map(([key, value])=>
                <MenuItem
                  className={`menu-item ${orderBy === key ? 'selected' : ''}`}
                  value={key}
                  key={key}
                  onClick={(event)=>{
                    handleRequestSort(event, key as keyof IRowData );
                    setAnchorEl(null);
                  }}
                >
                  {value}
                </MenuItem>,
              )}
            </Menu>
          </div>
          <div className='left'>
            {!['user', 'viewer'].includes(serviceContainer.usersStore.currentUser?.userRole
              ? serviceContainer.usersStore.currentUser?.userRole : '') ?
                <ButtonGroup
                  className='button_group'
                  aria-label='outlined primary button group'
                  sx={{ height: '32px' }}
                >
                  <Button
                    variant={showAll ? 'contained' : 'outlined'}
                    disabled={!privilegedRoles.includes(currentUserRole)}
                    disableTouchRipple
                    startIcon={<DateRange style={{ color:showAll ? 'white' : '' }}/>}
                    onClick={allBookingsClickHandler}
                    sx={{ width: '145px' }}
                  >
                    Все брони
                  </Button>
                  <Button
                    variant={showAll ? 'outlined' : 'contained'}
                    disableTouchRipple
                    startIcon={<EventAvailable style={{ color:showAll ? '' : 'white' }}/>}
                    onClick={myBookingsClickHandler}
                    sx={{ width: '150px' }}
                  >
                    Мои брони
                  </Button>
                </ButtonGroup>
              : null}
            <FormControlLabel
              className='switch_label'
              control={
                <AntSwitch
                  onChange={actualChangeHandler}
                  checked={!showActual}
                />
              }
              label='Архивные брони'
            />
            <SearchForm
              submitHandler={setSearchQuery}
              clearHandler={clearSearchHandler}
              placeholder="Поиск"
            />
          </div>
          <Tabs
            value={tabsValue}
            onChange={tabsChangeHandler}
            className='booking_header_tabs'
            sx={{
              width: 'auto',
              minHeight: 'auto',
            }}
            variant='scrollable'
          >
            <Tab
              label='Переговорные'
              disableTouchRipple
              sx={tabStyles}
            />
            <Tab
              label='Рабочие места'
              disableTouchRipple
              sx={{
                ...tabStyles,
                marginLeft: '20px',
              }}
            />
            <Tab
              label='Локеры'
              disableTouchRipple
              sx={{
                ...tabStyles,
                marginLeft: '20px',
              }}
            />
          </Tabs>
        </header>
        <main className={`booking_main ${loading ? 'loading' : ''}`}>
          {tableRows.length
            ? <BookingTable
                rows={tableRows}
                tabsValue={readableTabsValue}
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
              />
            : !loading ? <div className='not_found'>Информация по брони не найдена</div> : null}
        </main>
      </div>
      {loading ? <PageLoader/> : null}
    </>
  );
});
