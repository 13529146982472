import { ReactElement } from 'react';
import { serviceContainer } from 'services';
import { Divider, Tooltip, Link } from '@mui/material';
import { Status } from 'types/commonTypes';
import { User } from 'types/otherTypes';
import { statusMap, urlProfileDigitalleague } from 'utils';
import { PersonRounded, Grade } from '@mui/icons-material';

import './RoomTooltip.scss';

interface RoomTooltipProps {
  label: string;
  id: number | string;
  status: Status;
  children: ReactElement;
  isFlexible?: boolean;
  reservator?: string;
  reservatorIcon?: string | null;
  note?: string | null;
  disabled?: boolean;  
  onOpen?: () => void;
  onClose?: () => void;
  divisionAcl?: string[];
  reservatorInfo?: User[] | undefined;
  favorite?: boolean;
  open: boolean;
}

export const RoomTooltip = ({
  label,
  id,
  status,
  children,
  isFlexible,
  reservator,
  reservatorIcon,
  note,
  onOpen,
  onClose,
  disabled,
  divisionAcl,
  reservatorInfo,
  favorite,
  open,
}: RoomTooltipProps) => {

  const avatarReservator = () => {
    if (reservatorInfo && reservatorInfo[0]?.league_guid && (status === 'free' || !serviceContainer.usersStore.userIcon[reservatorIcon ?? ''])) {
      return (
        <Link
          href={`${urlProfileDigitalleague}${reservatorInfo[0]?.league_guid}`}
          target='_blank'
        >
          <PersonRounded className='icon' />
        </Link>
      );
    } else if (reservatorInfo && reservatorInfo[0]?.league_guid && serviceContainer.usersStore.userIcon[reservatorIcon ?? '']) {
      return (
        <Link
          href={`${urlProfileDigitalleague}${reservatorInfo[0]?.league_guid}`}
          target='_blank'
        >
          <img
            className='reservator_info_icon'
            src={URL.createObjectURL(serviceContainer.usersStore.userIcon[reservatorIcon ?? ''] as Blob | MediaSource)}
            alt={`${reservatorIcon}`}
          />
        </Link>
      );
    } else if (!(reservatorInfo && reservatorInfo[0]?.league_guid) && serviceContainer.usersStore.userIcon[reservatorIcon ?? '']) {
      <img
        className='reservator_info_icon'
        src={URL.createObjectURL(serviceContainer.usersStore.userIcon[reservatorIcon ?? ''] as Blob | MediaSource)}
        alt={`${reservatorIcon}`}
      />;
    } else {
      return <PersonRounded className='icon' />;
    }
  };

  const reservatorName = reservatorInfo && reservatorInfo[0]?.league_guid ? (
    <Link
      href={`${urlProfileDigitalleague}${reservatorInfo[0]?.league_guid}`}
      target='_blank'
      className='reservator-link'
    >
      {reservator}
    </Link>
  ) : reservator;

  return disabled ? children :
  <Tooltip
    classes={{ tooltip: '.Tooltip-tooltip room-tooltip' }}
    title={<div className='content'>
      {reservator && (
        <>
          <div className='reservator_info'>
            {avatarReservator()}
            <div className="reservator">
              {reservatorName}
            </div>
          </div>
          <Divider className='divider'/>
        </>
      )}
      <div className='top'>
        <div className='top-block'>
          <div className='title'>
            {label}
          </div>
          <div className='number'>
            №{id}
          </div>
        </div>
        {favorite ? (
          <div className='favorite-elem'>
            <Grade />
          </div>
        ) : null}
      </div>
      <div className="note">
        {note}
      </div>
      {divisionAcl?.length
        ? (
          <div className='divisions'>
            {`Доступно для бронирования: ${divisionAcl.join(', ')}`}
          </div>
        ) : null}
      {isFlexible && <>
        <div className="hint">
          Рабочее место FlexOffice. Доступно для бронирования не более чем на 3 дня
        </div>
        <Divider className='divider'/>
      </>}
      <div className='status'>
        <div className={`circle ${status}`}/>
        <div>
          {statusMap[status]}
        </div>
      </div>
    </div>}
    arrow
    onOpen={onOpen}
    onClose={onClose}
    open={open}
  >
    {children}
  </Tooltip>;
};
