import { observer } from 'mobx-react';
import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { Position } from 'types/commonTypes';
import {
  MAX_SCALE,
  MIN_SCALE,
  ROTATION_STEP,
  SCALE_STEP,
  closestZero,
  getLayout,
} from 'utils';
import { serviceContainer } from 'services';
import { isEqual } from 'lodash';

import './Draggable.scss';

interface DraggableProps {
  children:ReactNode;
  number:number;
  position:Position;
  rotation:number;
  isSelected: boolean;
  onKeyUp?: (key: string) => void;
  zIndex?: number;
  snap?: boolean;
  note?: string | null;
  divisionAcl?: string[];
  width?: number;
  userAcl?: string[];
  blurDraggable?: number | null;
}

export const Draggable = observer(({
  children,
  number,
  position,
  rotation,
  isSelected,
  onKeyUp,
  zIndex,
  snap,
  note,
  divisionAcl,
  userAcl,
  width,
  blurDraggable = null,
}: DraggableProps) => {

  const [localPosition, setLocalPosition] = useState(position);
  const [lockX, setLockX] = useState<number | null>(null);
  const [lockY, setLockY] = useState<number | null>(null);
  const [isDragging, setDragging] = useState(false);
  const [grabOffset, setGrabOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isSelected) {
      setLocalPosition(serviceContainer.roomStore.currentPosition);
    }
  }, [serviceContainer.roomStore.currentPosition]);

  useEffect(() => {
    if (!serviceContainer.roomStore.isEdit) {
      setLocalPosition(position);
    }
  }, [serviceContainer.roomStore.isEdit]);

  const ref = useRef<HTMLButtonElement>(null);
  const nodeRef = useRef(null);

  const scale = serviceContainer.roomStore.scale;

  const keyDownHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (event.key === 'Control') serviceContainer.roomStore.setCtrl(true);
    if (event.key === 'Shift') serviceContainer.roomStore.setShift(true);
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (serviceContainer.roomStore.isEditChangesDraggble) {
        return '';
      }
    };
  }, [serviceContainer.roomStore.isEditChangesDraggble]);

  const dependenciesEditChanges = [
    serviceContainer.roomStore.scale,
    serviceContainer.roomStore.initScale,
    position,
    serviceContainer.roomStore.currentPosition,
    serviceContainer.roomStore.currentRotation,
    rotation,
    serviceContainer.roomStore.currentWidth,
    width,
    serviceContainer.roomStore.getInitNote(),
    note,
    isSelected,
    divisionAcl,
    serviceContainer.roomStore.getInitDivisionAcl(),
    userAcl,
    serviceContainer.roomStore.getInitUserAcl(),
    serviceContainer.roomStore.draggables.length,
    serviceContainer.roomStore.initDraggables.length,
  ];

  useEffect(() => {
    if (isSelected) {
      if (serviceContainer.roomStore.scale === serviceContainer.roomStore.initScale &&
        isEqual(position, serviceContainer.roomStore.currentPosition) &&
        serviceContainer.roomStore.currentRotation === rotation &&
        serviceContainer.roomStore.currentWidth === width && 
        serviceContainer.roomStore.getInitNote() === (note || '' || null) &&
        isEqual((divisionAcl || null), serviceContainer.roomStore.getInitDivisionAcl()) &&
        isEqual((userAcl || null), serviceContainer.roomStore.getInitUserAcl()) &&
        serviceContainer.roomStore.draggables.length === serviceContainer.roomStore.initDraggables.length) {
        serviceContainer.roomStore.setIsEditChangesDraggble(false);
      } else {
        serviceContainer.roomStore.setIsEditChangesDraggble(true);
      }
    }
  }, dependenciesEditChanges);

  const keyUpHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    const { key } = event;
    if (key === 'ArrowUp' && !serviceContainer.roomStore.isShift) {
      if (serviceContainer.roomStore.isCtrl)
        serviceContainer.roomStore
          .setScale(Math.max(Math.min(+(serviceContainer.roomStore.scale + SCALE_STEP).toFixed(1), MAX_SCALE), MIN_SCALE));
      else {
        serviceContainer.roomStore.move(0, -1);
        setLocalPosition(serviceContainer.roomStore.currentPosition);
      }
    } else if (key === 'ArrowDown' && !serviceContainer.roomStore.isShift) {
      if (serviceContainer.roomStore.isCtrl)
        serviceContainer.roomStore
          .setScale(Math.max(Math.min(+(serviceContainer.roomStore.scale - SCALE_STEP).toFixed(1), MAX_SCALE), MIN_SCALE));
      else {
        serviceContainer.roomStore.move(0, 1);
        setLocalPosition(serviceContainer.roomStore.currentPosition);
      }
    } else if (key === 'ArrowLeft' && !serviceContainer.roomStore.isShift) {
      if (serviceContainer.roomStore.isCtrl) {
        const newAngle = +(serviceContainer.roomStore.currentRotation / ROTATION_STEP).toFixed(0) * ROTATION_STEP;
        serviceContainer.roomStore.setCurrentRotation(newAngle <= 0 ? 345 : newAngle - ROTATION_STEP);
      } else {
        serviceContainer.roomStore.move(-1, 0);
        setLocalPosition(serviceContainer.roomStore.currentPosition);
      }
    } else if (key === 'ArrowRight' && !serviceContainer.roomStore.isShift) {
      if (serviceContainer.roomStore.isCtrl) {
        const newAngle = +(serviceContainer.roomStore.currentRotation / ROTATION_STEP).toFixed(0) * ROTATION_STEP;
        serviceContainer.roomStore.setCurrentRotation(newAngle >= 345 ? 0 : newAngle + ROTATION_STEP);
      } else {
        serviceContainer.roomStore.move(1, 0);
        setLocalPosition(serviceContainer.roomStore.currentPosition);
      }
    } else if (key === 'Control') serviceContainer.roomStore.setCtrl(false);
    else if (key === 'Shift') serviceContainer.roomStore.setShift(false);

    if (onKeyUp) onKeyUp(key);
  };

  const onClick = () => {
    if (ref.current)
      ref.current.focus();
    if (isSelected) return;
    serviceContainer.roomStore.updateDraggables();
    serviceContainer.roomStore.setSelected(number);
    const draggable = serviceContainer.roomStore.draggables.find((d) => number === d.number);
    if (draggable) {
      serviceContainer.roomStore.setCurrentRotation(draggable.rotation);
      serviceContainer.roomStore.setCurrentFlexible(!!draggable.isFlexible);
      serviceContainer.roomStore.setDesktopNa(!!draggable.isDesktopNa);
      serviceContainer.roomStore.setDesktop(!!draggable.isDesktop);
      serviceContainer.roomStore.setCurrentWidth(draggable.width || 1);
      serviceContainer.roomStore.setCurrentPosition(draggable.position);
    }
  };

  const onDragStart = (_: DraggableEvent, { x, y }: DraggableData) => {
    if (!isSelected) return;
    if (ref.current)
      ref.current.focus();
    setGrabOffset({ x:x - localPosition.x, y: y - localPosition.y });
    setDragging(true);
  };

  const onDragUpdate = (_: DraggableEvent, { x, y }: DraggableData) => {
    if (!isSelected) return;
    if (!serviceContainer.roomStore.isSnap || !snap) {
      if (lockX) setLockX(null);
      if (lockY) setLockY(null);
      setLocalPosition(serviceContainer.roomStore.limitPosition({ x: x - grabOffset.x, y: y - grabOffset.y }));
      return;
    }
    const heightCurr = () => {
      switch (serviceContainer.roomStore.currentWidth) {
        case 4:
          return 79;
        case 3:
          return 73;
        case 2:
          return 67;
        case 1:
          return 61;
      }
      return 61;
    };
    const curr = getLayout(
      x - grabOffset.x,
      y - grabOffset.y,
      scale,
      serviceContainer.roomStore.currentRotation,
      31,
      heightCurr(),
    );
    const snapDistance = serviceContainer.roomStore.draggables
      .filter((d) => d.number !== number)
      .reduce((acc: { x: { value: number, triggers: number[] }, y: { value: number, triggers: number[] } }, d) => {
        const otherCurr = () => {
          switch (d.width) {
            case 4:
              return 79;
            case 3:
              return 73;
            case 2:
              return 67;
            case 1:
              return 61;
          }
          return 61;
        };
        const other = getLayout(
          d.position.x,
          d.position.y,
          scale,
          d.rotation,
          31,
          otherCurr(),
        );

        if (!curr || !other) return acc;
        const topToTop = other.top - curr.top;
        const bottomToBottom = other.bottom - curr.bottom;
        const leftToLeft = other.left - curr.left;
        const rightToRight = other.right - curr.right;
        const topToBottom = other.top - curr.bottom;
        const bottomToTop = other.bottom - curr.top;
        const leftToRight = other.left - curr.right;
        const rightToLeft = other.right - curr.left;
        const magnetY = closestZero([
          topToTop,
          bottomToBottom,
          topToBottom,
          bottomToTop,
        ]);
        const magnetX = closestZero([
          leftToLeft,
          leftToRight,
          rightToLeft,
          rightToRight,
        ]);
        if (Math.abs(magnetX) <= Math.abs(acc.x.value)) {
          acc.x.value = magnetX;
          if (Math.abs(magnetX) < 5 * scale) {
            acc.x.triggers = [...acc.x.triggers, d.number];
          }
        }
        if (Math.abs(magnetY) <= Math.abs(acc.y.value)) {
          acc.y.value = magnetY;
          if (Math.abs(magnetY) < 5 * scale) {
            acc.y.triggers = [...acc.y.triggers, d.number];
          }
        }
        return acc;
      }, { x: { value: Number.MAX_SAFE_INTEGER, triggers: [] }, y: { value: Number.MAX_SAFE_INTEGER, triggers: [] } });

    setLocalPosition(serviceContainer.roomStore.limitPosition({ x: x - grabOffset.x, y: y - grabOffset.y }));
    if (Math.abs(snapDistance.x.value) < 5 * scale) {
      setLockX(x - grabOffset.x + snapDistance.x.value);
      serviceContainer.roomStore.magnetTriggers = { ...serviceContainer.roomStore.magnetTriggers, x: snapDistance.x.triggers };
    } else {
      setLockX(null);
      serviceContainer.roomStore.magnetTriggers = { ...serviceContainer.roomStore.magnetTriggers, x: [] };
    }
    if (Math.abs(snapDistance.y.value) < 5 * scale) {
      setLockY(y - grabOffset.y + snapDistance.y.value);
      serviceContainer.roomStore.magnetTriggers = { ...serviceContainer.roomStore.magnetTriggers, y: snapDistance.y.triggers };
    } else {
      setLockY(null);
      serviceContainer.roomStore.magnetTriggers = { ...serviceContainer.roomStore.magnetTriggers, y: [] };
    }
  };

  const onDragEnd = () => {
    if (!isSelected) return;
    setDragging(false);
    const roundedPosition = {
      x: +(lockX ? lockX : localPosition.x).toFixed(1),
      y: +(lockY ? lockY : localPosition.y).toFixed(1),
    };
    setLocalPosition(roundedPosition);
    serviceContainer.roomStore.setCurrentPosition(roundedPosition);
    serviceContainer.roomStore.clearMagnetTriggers();
  };

  const DraggableInstance:any = DraggableCore;

  const horizontalLineStyles = {
    transform: `scaleY(${1 / (scale * serviceContainer.roomStore.scaleCoef)}) translateX(-50%)`,
  };

  const verticalLineStyles = {
    transform: `scaleX(${1 / (scale * serviceContainer.roomStore.scaleCoef)}) translateY(-50%)`,
  };

  return (
    <DraggableInstance
      onStart={onDragStart}
      onDrag={onDragUpdate}
      onStop={onDragEnd}
      scale={serviceContainer.roomStore.scaleCoef}
      disabled={!serviceContainer.roomStore.isEdit}
      nodeRef={nodeRef}
    >
      <div
        onClick={onClick}
        ref={nodeRef}
        className={`draggable ${isSelected ? 'selected' : ''} 
          ${serviceContainer.roomStore.isEdit && !isDragging ? 'grab' : ''} 
          ${blurDraggable ? blurDraggable === number ? '' : 'blur-draggable' : ''}`}
        style={{
          transform: `
            scale(${scale * serviceContainer.roomStore.scaleCoef})
            translate(
              ${((
            isSelected ? 
              isDragging ? 
                lockX ? lockX : localPosition.x : 
                serviceContainer.roomStore.currentPosition.x : 
              position.x)) / scale}px, 
              ${((isSelected ? 
                isDragging ? 
                  lockY ? lockY : localPosition.y : 
                  serviceContainer.roomStore.currentPosition.y : 
                position.y)) / scale}px
            )
            rotate(${isSelected ? serviceContainer.roomStore.currentRotation : rotation}deg)
            `,
          zIndex: zIndex ? zIndex : isSelected ? 3 : 2,
        }}
      >
        <button
          ref={ref}
          className="focusButton"
          onKeyDown={keyDownHandler}
          onKeyUp={keyUpHandler}
        />
        {isDragging && serviceContainer.roomStore.isSnap && snap &&
          <>
            <div
              className="line top"
              style={horizontalLineStyles}
            />
            <div
              className="line bottom"
              style={horizontalLineStyles}
            />
            <div
              className="line left"
              style={verticalLineStyles}

            />
            <div
              className="line right"
              style={verticalLineStyles}
            />
          </>}
        {children}
      </div>
    </DraggableInstance>
  );
});
