import { ReactNode, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import './ModalCustom.scss';

type ModalCustomProps = {
  title: string;
  children: ReactNode | string;
  open: boolean;
  onClose: (value: boolean | SyntheticEvent) => void;
  onSave: () => void;
  loadingSave?: boolean;
  disabledSave?: boolean;
  saveLabel?: string;
  cancelLabel?: string;
  className?: string;
  scroll?: 'paper' | 'body' | undefined;
};

// todo вынести блок кнопок
const ModalCustom = observer(({
  title,
  children,
  open,
  onClose = () => {},
  onSave = () => {},
  loadingSave,
  disabledSave,
  saveLabel = 'Сохранить',
  cancelLabel = 'Отменить',
  className,
  scroll,
}: ModalCustomProps) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: 'paper' }}
      className={`modal ${className}`}
      onClick={(e) => e.stopPropagation()}
      scroll={scroll}
    >
      <DialogTitle className='modal-title'>
        {title}
        <IconButton
          aria-label="close"
          className="modal-close-button"
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className='modal-content'>
        {children}
      </DialogContent>
      <DialogActions
        className='modal-content-buttons'
      >
        <Button
          onClick={onClose}
          className='modal-content-btn-outlined'
          sx={{
					  border: '1px solid #168FF7',
					  fontWeight: 600,
					  fontSize: '15px',
					  color: '#168FF7',
          }}
          variant="outlined"
        >
          {cancelLabel}
        </Button>
        <LoadingButton
          disabled={disabledSave}
          onClick={onSave}
          loading={loadingSave}
          className='modal-content-btn-contained'
          variant="contained"
        >
          {saveLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalCustom;
