import { Route } from 'react-router-dom';
import {
  BookingPage,
  OfficesPage,
  Page403,
  Page404,
  RegionsPage,
  RoomsPage,
  RoomPage,
  CalendarPage,
  ProfilePage,
  FavoritesPage,
  HelpPage,
  AdministrationPage,
  DistributionPage,
  Page502,
} from './pages';
import { FunctionComponent } from 'react';

interface RouteItem {
  path: string;
  component: FunctionComponent;
  getLinkTo?: (regionId: string, officeId: string, roomId: string, workspaceId: string) => string;
  pageName: string;
}

export const routes: { [key: string]: RouteItem } = {
  INDEX: {
    path: '/',
    component: RegionsPage,
    pageName: 'Карта',
  },
  WORKSPACES_PAGE: {
    path: '/:regionId/offices/:officeId/rooms/:roomId/workspaces',
    component: RoomPage,
    pageName: 'Кабинет',
    getLinkTo: (regionId, officeId, roomId) => `/${regionId}/offices/${officeId}/rooms/${roomId}/workspaces`,
  },
  OLD_ROOM_PAGE: {  // для старых QR-кодов
    path: '/:regionId/offices/:officeId/rooms/:roomId',
    component: RoomPage,
    pageName: 'Кабинет',
    getLinkTo: (regionId, officeId, roomId) => `/${regionId}/offices/${officeId}/rooms/${roomId}`,
  },
  ROOMS_PAGE: {
    path: '/:regionId/offices/:officeId/rooms',
    component: RoomsPage,
    pageName: 'Комнаты',
    getLinkTo: (regionId, officeId) => `/${regionId}/offices/${officeId}/rooms`,
  },
  OFFICES_PAGE: {
    path: '/:regionId/offices',
    component: OfficesPage,
    pageName: 'Офисы',
    getLinkTo: (regionId) => `/${regionId}/offices`,
  },
  BOOKING_PAGE: {
    path: '/booking',
    component: BookingPage,
    pageName: 'Брони',
  },
  CALENDAR_PAGE: {
    path: '/:regionId/offices/:officeId/rooms/:roomId/workspaces/:workspaceId',
    component: CalendarPage,
    pageName: 'Календарь бронирования',
    getLinkTo: (regionId, officeId, roomId, workspaceId) => `/${regionId}/offices/${officeId}/rooms/${roomId}/workspaces/${workspaceId}`,
  },
  ADMINISTRATION_PAGE: {
    path: '/administration',
    component: AdministrationPage,
    pageName: 'Администрирование',
  },
  PROFILE_PAGE: {
    path: '/profile',
    component: ProfilePage,
    pageName: 'Личный кабинет',
  },
  HELP_PAGE: {
    path: '/help',
    component: HelpPage,
    pageName: 'Помощь',
  },
  FAVORITES_PAGE: {
    path: '/favorites',
    component: FavoritesPage,
    pageName: 'Мои помещения',
  },
  DISTRIBUTION: {
    path: '/distribution',
    component: DistributionPage,
    pageName: 'Центр оповещений',
  },
  ERROR_403: {
    path: '/forbidden',
    component: Page403,
    pageName: 'Доступ запрещен',
  },
  ERROR_502: {
    path: '/notavailable',
    component: Page502,
    pageName: 'Сервис временно не доступен',
  },
  ERROR_404: {
    path: '*',
    component: Page404,
    pageName: 'Ничего не найдено',
  },
};

export const appRoutes = Object.keys(routes).map((key: string) => (
  <Route
    exact
    path={routes[key].path}
    component={routes[key].component}
    key={key}
  />
));
