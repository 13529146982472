import { makeAutoObservable, toJS } from 'mobx';
import { SearchAvailableDto, PlaceType } from '../types/commonTypes';
import { TWorkspaceTableInternal } from '../pages/AdministrationPage/WorkspacesTable/WorkspacesTable.type';
import { TSnackbarProps } from '../pages/AdministrationPage/PremisesTable/PremisesTable.type';
import { PaginationParams, IWorkspacesParamsObject } from 'types/otherTypes';

export class WorkspacesStore {
  private _workspaces: { [key: string]: PlaceType[] } = {};
  private _workspacesTable: TWorkspaceTableInternal[] = [];
  private _filter: string = '';
  private _isAddingWorkspace = false;
  private _dropdownWorkspace: TWorkspaceTableInternal | null = null;
  private _dropdownAnchor: HTMLElement | null = null;
  private _isEditHandler: (() => void) | null = null;
  private _workspaceInfoForEdit: TWorkspaceTableInternal | null = null;
  private _workspacesTypeOption: string[] = [];
  private _snackbar: TSnackbarProps = {
    open: false,
    message: '',
    severity: 'error',
  };
  private _workspacesLoading = false;
  private _paginationParams: PaginationParams | undefined = undefined;
  private _workspacesAvailable: SearchAvailableDto[] = [];
  private _workspacesParamsObject: IWorkspacesParamsObject | null = null;
  private _workspaceAvailableId: number | null = null; 

  constructor() {
    makeAutoObservable(this);
  }

  get filter() {
    return this._filter;
  }

  setFilter(value: string) {
    this._filter = value;
  }

  get isAddingWorkspace() {
    return this._isAddingWorkspace;
  }

  setIsAddingWorkspace(value: boolean) {
    this._isAddingWorkspace = value;
  }

  get dropdownWorkspace() {
    return this._dropdownWorkspace;
  }

  setDropdownWorkspace(value: TWorkspaceTableInternal) {
    this._dropdownWorkspace = value;
  }

  get dropdownAnchor() {
    return this._dropdownAnchor;
  }

  setDropdownAnchor(value: HTMLElement | null) {
    this._dropdownAnchor = value;
  }

  get isEditHandler() {
    return this._isEditHandler;
  }

  setIsEditHandler(value: (() => void) | null) {
    this._isEditHandler = value;
  }

  get workspaceInfoForEdit() {
    return this._workspaceInfoForEdit;
  }

  setWorkspaceInfoForEdit(value: TWorkspaceTableInternal | null) {
    this._workspaceInfoForEdit = value;
  }

  get workspacesTypeOption() {
    return this._workspacesTypeOption;
  }

  setWorkspacesTypeOption(value: string[]) {
    this._workspacesTypeOption = value;
  }

  get snackbar() {
    return this._snackbar;
  }

  setSnackbar(value: TSnackbarProps) {
    this._snackbar = value;
  }

  get workspaces(): [key: string, value: PlaceType[]][] {
    return toJS(Object.entries(toJS(this._workspaces)));
  }

  get workspacesTable() {
    return toJS(this._workspacesTable);
  }

  set workspacesTable(workspacesTable) {
    this._workspacesTable = workspacesTable;
  }

  hasWorkspaces(key: string): boolean {
    return toJS(Object.keys(this._workspaces).includes(key));
  }

  getWorkspacesByKey(key: string): PlaceType[] {
    return toJS(this._workspaces[key]);
  }

  setWorkspacesByRoomId(roomId: string, data: PlaceType[]) {
    this._workspaces[roomId] = data;
  }

  get workspacesLoading() {
    return this._workspacesLoading;
  }

  set workspacesLoading(isLoading) {
    this._workspacesLoading = isLoading;
  }

  get paginationParams() {
    return toJS(this._paginationParams); 
  }

  set paginationParams(params) {
    this._paginationParams = params; 
  }

  get workspacesAvailable() {
    return this._workspacesAvailable;
  }

  set workspacesAvailable(workspacesAvailable) {
    this._workspacesAvailable = workspacesAvailable;
  }

  get workspacesParamsObject() {
    return this._workspacesParamsObject;
  }

  set workspacesParamsObject(workspacesParamsObject) {
    this._workspacesParamsObject = workspacesParamsObject;
  }

  get workspaceAvailableId() {
    return this._workspaceAvailableId;
  }

  set workspaceAvailableId(workspaceAvailableId) {
    this._workspaceAvailableId = workspaceAvailableId;
  }
}
