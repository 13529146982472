import { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReactComponent as DoorSvg } from 'assets/svg/decor/door.svg';
import { serviceContainer } from 'services';
import { RoomTooltip } from '../../RoomTooltip/RoomTooltip';
import { Status } from 'types/commonTypes';
import { Grade } from '@mui/icons-material';

import './Door.scss';

interface DoorProps {
  number: number;
  switchFree?: boolean;
}

const isMobile = window.innerWidth < 600;

export const Door = observer(({ number, switchFree }: DoorProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const history = useHistory();
  const { roomId } = useParams<{ roomId: string, officeId: string }>();
  const divRef = useRef<HTMLDivElement>(null);

  const workspaceAvailableId = serviceContainer.workspacesStore.workspaceAvailableId;

  useEffect(() => {
    if (workspaceAvailableId === number) {
      divRef.current?.focus();
    }
  }, [workspaceAvailableId, number]); 

  const onClick = () => {
    if (serviceContainer.roomStore.isEdit) {
      if (serviceContainer.roomStore.selected === number) {
        serviceContainer.roomStore.setSelected(null);
      } else {
        serviceContainer.roomStore.setSelected(number);
      }
    } else {
      history.push(`${history.location.pathname}/${roomId}.${number}`);
    }
  };

  const biggerSide = serviceContainer.roomStore.columns > serviceContainer.roomStore.rows ?
    serviceContainer.roomStore.columns :
    serviceContainer.roomStore.rows;
  const scale = 65 / biggerSide;
  const currentDoor = serviceContainer.roomStore.draggables[number - 1];

  const reservatorIcon = currentDoor?.reservatorIcon || '';
  const hasUserIcon = Object.keys(serviceContainer.usersStore.userIcon).includes(reservatorIcon);

  const { 
    status, 
    reservator, 
    favorite = false, 
    reservationAvailable = false,
  } = currentDoor ? currentDoor : { status: 'free' as Status, reservator: undefined };

  const blurLocker = !reservationAvailable && switchFree;

  const reservatorInfo = useMemo(() => {
    if (reservator && serviceContainer.usersStore.users) {
      return serviceContainer.usersStore.users.filter((item) => item.userName.toLowerCase() === reservator.toLowerCase());
    }
  }, [reservator, serviceContainer.usersStore.users]);

  useEffect(() => {
    if (reservatorIcon && !hasUserIcon && isTooltipOpen) {
      serviceContainer.usersService.getBlobImageByUrl(reservatorIcon).then((data) => {
        serviceContainer.usersStore.setUserIcon(data, reservatorIcon);
      });
    }
  }, [reservatorIcon, isTooltipOpen, hasUserIcon]);

  return (
    <RoomTooltip
      label='Локер'
      id={number}
      status={status}
      reservator={reservator}
      reservatorIcon={reservatorIcon}
      reservatorInfo={reservatorInfo}
      disabled={serviceContainer.roomStore.isEdit || blurLocker}
      onOpen={()=>setTooltipOpen(true)}
      onClose={()=>setTooltipOpen(false)}
      divisionAcl={currentDoor?.division_acl}
      open={isTooltipOpen}
    >
      <div
        ref={divRef}
        tabIndex={-1}
        className={`door ${serviceContainer.roomStore.selected === number 
          ? 'selected' : workspaceAvailableId && workspaceAvailableId > 0 
            ? workspaceAvailableId === number ? 'focus' : 'zIndex' : ''}`}
        onClick={blurLocker ? () => null : onClick}
        style={isMobile ? {
          height: `calc(calc(100vh - ${scale})/${serviceContainer.roomStore.rows})vh`,          
          width: `${(100 - scale) / serviceContainer.roomStore.columns}vw`,
        } : {
          height:`${scale}vh`,
          width:`${scale}vh`,
        }}
        onBlur={() => serviceContainer.workspacesStore.workspaceAvailableId = null}
      >
        <DoorSvg/>
        <div 
          className={`circle ${status} ${blurLocker ? 'blur-locker' : Number(workspaceAvailableId) === number ? 'pulse' : ''}`}
        >
          {number}
        </div>
        {favorite ? (
          <div className='door-favorite-icon'>
            <Grade />
          </div>
        ) : null}
      </div>
    </RoomTooltip>);
});
