import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { YMaps } from '@pbe/react-yandex-maps';
import { App } from './UI/components';
import './styles/mainModule.scss';

render(
  <BrowserRouter>
    <StrictMode>
      <YMaps query={{ apikey: 'bd421360-6600-4bea-a53c-a136b9d56b9e' }}>
        <App />
      </YMaps>
    </StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);
