import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { Position, Status } from 'types/commonTypes';
import { serviceContainer } from 'services';
import { Draggable } from 'UI/components';
import { ReactComponent as MeetingTableFree } from 'assets/svg/decor/tables/table_meeting_free.svg';
import { ReactComponent as MeetingTableReserved } from 'assets/svg/decor/tables/table_meeting_reserved.svg';
import { ReactComponent as MeetingTableOccupied } from 'assets/svg/decor/tables/table_meeting_occupied.svg';
import { RoomTooltip } from '../../RoomTooltip/RoomTooltip';
import { OUTLINE_THICKNESS } from '../../../../../utils';

import './MeetingTable.scss';

interface MeetingTableProps {
  number:number,
  position:Position,
  rotation:number,
  isSelected: boolean,
  status: Status,
  reservator?: string;
  reservatorIcon?: string | null;
  note?: string | null;
  divisionAcl?: string[];
  favorite?: boolean;
  blurMeeting?: boolean;
}

export const MeetingTable = observer(({
  number,
  position,
  rotation,
  isSelected,
  status,
  reservator,
  reservatorIcon,
  note,
  divisionAcl,
  favorite,
  blurMeeting,
}: MeetingTableProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const history = useHistory();
  const { roomId } = useParams<{ roomId: string, officeId: string }>();

  const onClick = () => {
    if (serviceContainer.roomStore.isEdit) return;
    history.push(`${history.location.pathname}/${roomId}.${number}`);
  };

  const hasUserIcon = Object.keys(serviceContainer.usersStore.userIcon).includes(reservatorIcon ?? '');

  useEffect(() => {
    if (reservatorIcon && !hasUserIcon && isTooltipOpen) {
      serviceContainer.usersService.getBlobImageByUrl(reservatorIcon).then((data) => {
        serviceContainer.usersStore.setUserIcon(data, reservatorIcon);
      });
    }
  }, [reservatorIcon, isTooltipOpen, hasUserIcon]);

  const reservatorInfo = useMemo(() => {
    if (reservator && serviceContainer.usersStore.users) {
      return serviceContainer.usersStore.users.filter((item) => item.userName.toLowerCase() === reservator.toLowerCase());
    }
  }, [reservator, serviceContainer.usersStore.users]);

  const svgStyles = {
    strokeWidth: `${OUTLINE_THICKNESS / serviceContainer.roomStore.scale}px`,
  };

  return (
    <Draggable
      number={number}
      position={position}
      rotation={rotation}
      isSelected={isSelected}
    >
      <RoomTooltip
        label='Переговорочная'
        id={number}
        status={status}
        reservator={reservator}
        note={note}
        reservatorIcon={reservatorIcon}
        reservatorInfo={reservatorInfo}
        disabled={serviceContainer.roomStore.isEdit || blurMeeting}
        onOpen={()=>setTooltipOpen(true)}
        onClose={()=>setTooltipOpen(false)}
        divisionAcl={divisionAcl}
        favorite={favorite}
        open={isTooltipOpen}
      >
        <div
          className={`meeting-table${isSelected ? ' selected' : ''} ${blurMeeting ? 'blur' : ''}`}
          onClick={blurMeeting ? () => null : onClick}
          style={{
            cursor: serviceContainer.roomStore.isEdit ? undefined : 'pointer',
          }}
        >
          {{
            free: <MeetingTableFree style={svgStyles} />,
            occupied: <MeetingTableOccupied style={svgStyles} />,
            reserved: <MeetingTableReserved style={svgStyles} />,
          }[status]}
        </div>
      </RoomTooltip>
    </Draggable >
  );
});
